<template>
  <div class="valuation-records">
    <div class="valuation-records-title">{{ t('valuation.crypto.records') }}</div>
    <div class="valuation-records-cards">
      <div
        v-for="(item, index) in props.list"
        :key="index"
        class="valuation-records-cards-item"
        @click="emit('onClick', item)"
      >
        <div class="valuation-records-cards-item-title">{{ transformI18n(item?.title) }}</div>
        <div class="valuation-records-cards-item-content">{{ transformI18n(item?.content) }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { transformI18n } from '@/i18n/index'

const props = defineProps<{
  list: Array<Record<string, string>>
}>()

const emit = defineEmits(['onClick'])
const { t } = useI18n()
</script>
<style lang="scss" scoped>
.valuation-records {
  padding: 24px;

  &-title {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-cards {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;

    &-item {
      cursor: pointer;
      display: flex;
      width: 232px;
      height: 98px;
      padding: 16px;
      box-sizing: border-box;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 12px;

      border-radius: 5px;
      border: 1px solid #edf0f3;
      background: #f9fafb;

      &-title {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &-content {
        margin-top: 4px;
        color: #aaafb6;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
</style>
